import Vue from "vue";
import axios from "axios";
import { ObjectDataSource } from "./ObjecDatatSource";

export class FileDataSource extends ObjectDataSource {
  async getFile(fileId: number) {
    try {
      const url = `manage/${this.className}/${fileId}/file`;
      const { data } = await axios.get(url);
      return data;
    } catch (err) {
      throw err;
    }
  }
}
